import React, { useState, useEffect } from "react";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import WorkIcon from '@mui/icons-material/Work';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { NavLink } from "react-router-dom";
import { useGlobalContext } from "global/context";
import { HiUserGroup } from "react-icons/hi"; // connections
import { FaUserEdit } from "react-icons/fa"; //Experts
import { FaUserTie } from "react-icons/fa"; // profile
import { RiQuestionAnswerFill } from "react-icons/ri";


function MobileFooter() {
    const [value, setValue] = useState('home');
    const { userData } = useGlobalContext();

    // useEffect(() => {
    //     if (userData?.id !== undefined) {
    //         // Your logic here
    //     }
    // }, [userData?.id]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    console.log("User: ", userData)
    const containerStyle = {
        marginTop: "50px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        position: "fixed",
        bottom: 0,
        width: "100%",
        zIndex: 1000,
        backgroundColor: "white",
    };

    return (
        <div style={containerStyle}>
            <BottomNavigation
                style={{ width: "95%" }}
                value={value}
                onChange={handleChange}
                showLabels
            >
                <BottomNavigationAction
                    label="Profile"
                    value="profile"
                    icon={<AccountCircleIcon />}
                    component={NavLink}
                    to="/profile"
                />
                {userData?.role === 'student' ? (
                    [
                        <BottomNavigationAction
                            key="experts"
                            label="Experts"
                            value="experts"
                            icon={<FaUserEdit />}
                            component={NavLink}
                            to="/experts"
                            exact
                        />,
                        <BottomNavigationAction
                            key="q&a"
                            label="Q&A"
                            value="q&a"
                            icon={<RiQuestionAnswerFill />}
                            component={NavLink}
                            to="/questions"
                        />,
                        <BottomNavigationAction
                            key="institute"
                            label="Institute"
                            value="institute"
                            icon={<SchoolIcon />}
                            component={NavLink}
                            to="/institutes"
                        />
                    ]) : ([
                        <BottomNavigationAction
                            key="q&a"
                            label="Q&A"
                            value="q&a"
                            icon={<RiQuestionAnswerFill />}
                            component={NavLink}
                            to="/questions"
                        />,
                        <BottomNavigationAction
                            key="job"
                            label="Job"
                            value="job"
                            icon={<WorkIcon />}
                            component={NavLink}
                            to="/careers"
                        />,
                        <BottomNavigationAction
                            key="institute"
                            label="Institute"
                            value="institute"
                            icon={<SchoolIcon />}
                            component={NavLink}
                            to="/institutes"
                        />

                    ])}
                <BottomNavigationAction
                    label="Connections"
                    value="connections"
                    icon={<HiUserGroup />}
                    component={NavLink}
                    to="/profile/connection"
                />

            </BottomNavigation>
        </div>
    );
}

export default MobileFooter;
