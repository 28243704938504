import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import InstituteSidebar from "./Component/InstituteSidebar";
import { Divider, IconButton } from "@mui/joy";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import { styled } from "@mui/system";

const InstituteParentContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  height: "100vh",
  [`@media (max-width: 768px)`]: {
    flexDirection: "column",
  },
});

const SidebarContainer = styled("div")(({ hide }) => ({
  flex: hide ? "0 0 0" : "0 0 450px", // Set to 450px width for mobile view when shown
  overflow: "hidden",
  transition: "width 0.3s ease",
  width: hide ? "0" : "450px", // For mobile, set width to 450px when shown
  [`@media (max-width: 768px)`]: {
    width: hide ? "0" : "450px", // Set width to 450px on mobile
  },
  [`@media (min-width: 768px)`]: {
    flex: hide ? "0 0 0" : "0 0 250px", // Set to 250px for desktop view when shown
    width: hide ? "0" : "250px", // Set width to 250px on desktop
  },
}));

const ContentContainer = styled("div")(({ hide }) => ({
  flex: "1 1 auto",
  transition: "flex 0.3s ease",
  width: hide ? "100%" : "calc(100% - 450px)", // For mobile, subtract 450px
  position: "relative",
  [`@media (min-width: 768px)`]: {
    width: hide ? "100%" : "calc(100% - 250px)", // For desktop, subtract 250px
  },
  [`@media (max-width: 768px)`]: {
    width: "100%", // For mobile, take the full width
  },
}));


const CustomDivider = styled(Divider)(({ hide }) => ({
  display: hide ? "none" : "block",
  width: "1px",
  [`@media (max-width: 768px)`]: {
    display: "none",
  },
}));

const ToggleButton = styled(IconButton)({
  position: "absolute",
  top: "1rem",
  left: "1rem",
  zIndex: 1,
});

function InstituteParent() {
  const [hideProfileDiv, setHideProfileDiv] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setHideProfileDiv(false); // Show sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setHideProfileDiv((prev) => !prev);
  };

  return (
    <InstituteParentContainer>
      <SidebarContainer hide={hideProfileDiv}>
        <InstituteSidebar />
      </SidebarContainer>
      <CustomDivider hide={hideProfileDiv} orientation="vertical" variant="middle" />
      <ContentContainer hide={hideProfileDiv}>
        {isMobile && (
          <ToggleButton onClick={toggleSidebar}>
            {hideProfileDiv ? <ArrowForward /> : <ArrowBack />}
          </ToggleButton>
        )}
        <Outlet />
      </ContentContainer>
    </InstituteParentContainer>
  );
}

export default InstituteParent;
