import React, { useState, useEffect } from "react";
import { TextField, Checkbox, FormControlLabel, Radio, RadioGroup, Button, MenuItem, Select } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useGlobalContext } from "global/context";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import subjects from '../../../data/subject.json'
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Use named export instead of default


const UserAddress = () => {
    const { userData, api } = useGlobalContext();
    const navigate = useNavigate()
    const location = useLocation();
    const [role, setRole] = useState(location.state?.role);
    const [UserId, setUserId] = useState(location.state?.registeredId);
    const [clientOtp, setClientOtp] = useState('')
    const [isVarified, setIsVarified] = useState(false)

    // Extract token from the URL query parameters
    // Extract token from the URL query parameters
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get("token");

    useEffect(() => {
        const verifyOtp = async () => {
            if (token && !isVarified) {
                try {
                    const decodedToken = jwtDecode(token);
                    console.log("Decoded Token:", decodedToken);
    
                    const { role, id: userId, clientotp: code } = decodedToken.data;
                    setRole(role);
                    setUserId(userId);
                    setClientOtp(code);
    
                    const otpValues = { userId, code };
                    console.log("OTP Values being sent:", otpValues);
    
                    const otpRes = await api.post(`/app/auth/verify-otp`, otpValues);
    
                    if (otpRes.status === 200) {
                        console.log("OTP Verified:", otpRes.data.message);
                        setIsVarified(true)
                        // const redirectTo = otpRes.data.redirectTo || "/profile";
                        // navigate(redirectTo);
                        // window.location.reload();
                    } else {
                        console.error("OTP verification failed:", otpRes.data.message);
                        // Display user-friendly error
                        alert("OTP verification failed: " + otpRes.data.message);
                    }
                } catch (error) {
                    if (error.response) {
                        console.error("Error response data:", error.response.data);
                    } else if (error.request) {
                        console.error("Error request:", error.request);
                    } else {
                        console.error("Error message:", error.message);
                    }
                }
            } else if (!token) {
                console.error("Token not found in URL.");
            }
        };
    
        verifyOtp();
    }, [token, api, navigate, isVarified ]);
    
    console.log("role : ", role)
    console.log("UserId : ", UserId)


    const [formData, setFormData] = useState({
        classLevel: "",
        selectedSubjects: [],
        selectedExpertise: [],
        teachingClasses: [],
        teachAllSubjects: false,
        gender: "",
        dateOfBirth: null,
        area: "",
        city: "",
        district: "",
        state: "",
        pincode: "",
    });

    const [showAddressForm, setShowAddressForm] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);


    const classOptions = ["1-5", "6-8", "9-10", "11", "12", "Graduation", "Post Graduation", "Diploma", "Freelancing Course"];

    const formatDateString = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleClassChange = (event) => {
        const { value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            teachingClasses: typeof value === 'string' ? value.split(',') : value, // Handle multiple selections
        }));
    };

    const handleSubjectChange = (event) => {
        const { value } = event.target;
        setFormData((prevData) => {
            const updatedSubjects = typeof value === 'string' ? value.split(',') : value; // Handle multiple selections
            const updatedExpertise = prevData.selectedExpertise.filter((expertise) => {
                const subject = subjects.AcademicDisciplines.find((sub) => Object.keys(sub.Subjects).includes(expertise));
                return subject && updatedSubjects.includes(subject.Category);
            });

            return {
                ...prevData,
                selectedSubjects: updatedSubjects,
                selectedExpertise: updatedExpertise, // Update expertise based on selected subjects
            };
        });
    };

    const handleExpertiseChange = (expertise, checked) => {
        setFormData((prevData) => {
            const updatedExpertise = checked
                ? [...prevData.selectedExpertise, expertise] // Add expertise if checked
                : prevData.selectedExpertise.filter((exp) => exp !== expertise); // Remove expertise if unchecked

            return {
                ...prevData,
                selectedExpertise: updatedExpertise, // Store updated expertise
            };
        });
    };

    // In your render method, update the expertise dropdown logic:
    const allExpertiseOptions = subjects.AcademicDisciplines.flatMap((sub) => Object.keys(sub.Subjects));


    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;

        // If the checkbox is checked, add the class to teachingClasses array, otherwise remove it
        const updatedClasses = checked
            ? [...formData.teachingClasses, value] // Add selected class
            : formData.teachingClasses.filter((cls) => cls !== value); // Remove unselected class

        // Update the formData with new teachingClasses array
        setFormData({ ...formData, teachingClasses: updatedClasses });
    };

    const handleTeachAllSubjectsChange = (e) => {
        setFormData({ ...formData, teachAllSubjects: e.target.checked });
    };

    const handleNext = (e) => {
        e.preventDefault();
        setShowAddressForm(true); // Show address form on clicking next
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validate pincode (should be 6 digits)
        if (!/^\d{6}$/.test(formData.pincode)) {
            alert("Please enter a valid 6-digit pincode.");
            return;
        }
        console.log("form data : ",formData)
        try {
            const res = await api.post(`/app/auth/addiinfo?userId=${UserId}`, formData);
            if (res.status == 201) {
                console.log("Data Submitted!")
                navigate("/auth/login")
            }
        } catch (error) {
            console.log(error)
        }
        setIsSubmitted(true);
        resetForm();

    };

    const subjected = subjects.AcademicDisciplines
    // console.log("subjected :", subjected)
    // Get subjects based on selected class level
    const getSubjectOptions = () => {
        const Subjects = subjects.AcademicDisciplines.map((sub) => sub.Category)
        return Subjects;
    };

    const getExpertiseOptions = () => {
        // Get unique expertise options based on selected subjects
        const expertiseSet = new Set();
        formData.selectedSubjects.forEach((subject) => {
            const selectedSubject = subjects.AcademicDisciplines.find((sub) => sub.Category === subject);
            if (selectedSubject) {
                Object.keys(selectedSubject.Subjects).forEach((expertise) => expertiseSet.add(expertise));
            }
        });
        return Array.from(expertiseSet); // Convert the Set back to an array
    };
    const shouldShowSubjects = () => {
        // Show subjects dropdown if any class level greater than 8 is selected
        const upperClasses = ["9-10", "11", "12", "Graduation", "Post Graduation", "Diploma", "Freelancing Course"];
        return formData.teachingClasses.some((cls) => upperClasses.includes(cls));
    };

    const resetForm = () => {
        setFormData({
            area: '',
            city: '',
            district: '',
            state: '',
            pincode: '',
        });
    };

    // Define MenuProps to limit height and make the list scrollable
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 200, // Maximum height for the dropdown
                overflow: 'auto', // Enable scrolling when the content exceeds max height
            },
        },
    };

    return (
        <div className="flex flex-col items-center space-y-6 p-4">
            {!showAddressForm ? (
                // First Form: Class, Gender, Date of Birth
                <form
                    onSubmit={handleNext}
                    className="bg-white p-6 rounded shadow-md w-full max-w-lg space-y-6"
                >
                    <h2 className="text-2xl font-bold text-center mb-4">User  Information</h2>

                    {/* Conditional Form for class based on role */}
                    {role === "student" && (
                        <>
                            <h3 className="text-lg font-semibold">Which Class Are You Studying?</h3>
                            <FormControl fullWidth variant="outlined" color="secondary" required>
                                <InputLabel>Select your class</InputLabel>
                                <Select
                                    label="Class Level"
                                    name="classLevel"
                                    value={formData.classLevel}
                                    onChange={handleChange}
                                    displayEmpty // This will ensure the placeholder appears
                                >
                                    <MenuItem value="" disabled>
                                        Select your class
                                    </MenuItem>
                                    {classOptions.map((cls) => (
                                        <MenuItem key={cls} value={cls}>
                                            <FormControlLabel
                                                value={cls}
                                                control={
                                                    <Radio
                                                        checked={formData.classLevel === cls}
                                                        color="secondary"
                                                    />
                                                }
                                                label={cls}
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </>
                    )}

                    {/* Conditionally render Subject Selection if class is 11 or greater */}

                    {(formData.classLevel === "11" || formData.classLevel === "12") && (
                        <FormControl fullWidth variant="outlined" color="secondary" required>
                            <InputLabel>Select Subject</InputLabel>
                            <Select
                                label="Select Subject"
                                name="selectedSubjects"
                                value={formData.selectedSubjects}
                                onChange={handleChange}
                            >
                                <MenuItem value="" disabled>
                                    Select your subject
                                </MenuItem>
                                <MenuItem value="Science">Science</MenuItem>
                                <MenuItem value="Arts">Arts</MenuItem>
                                <MenuItem value="Commerce">Commerce</MenuItem>
                            </Select>
                        </FormControl>
                    )}

                    {/* Conditionally render Subject Selection if class is graduation or greater */}
                    {(
                        formData.classLevel === "Graduation" ||
                        formData.classLevel === "Post Graduation" ||
                        formData.classLevel === "Diploma" ||
                        formData.classLevel === "Freelancing Course") && (
                            <FormControl fullWidth variant="outlined" color="secondary" required>
                                <InputLabel>Select Subject</InputLabel>
                                <Select
                                    label="Select Subject"
                                    name="selectedSubjects"
                                    value={formData.selectedSubjects}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="" disabled>
                                        Select your subject
                                    </MenuItem>
                                    {getSubjectOptions().map((subject) => (
                                        <MenuItem key={subject} value={subject}>
                                            {subject}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                    {/* Teacher or Professional Select */}
                    {(role === "teacher" || role === "professional") && (
                        <div>
                            <div className="flex flex-wrap justify-between">
                                <div className="w-full md:w-1/2">
                                    {/* Teaching Classes */}
                                    {/* <h3 className="text-lg font-semibold">Classes You Teach</h3> */}
                                    <FormControl fullWidth variant="outlined" color="secondary">
                                        <InputLabel>Teaching Classes</InputLabel>
                                        <Select
                                            label="Teaching Classes"
                                            name="teachingClasses"
                                            multiple
                                            value={formData.teachingClasses}
                                            onChange={handleClassChange}
                                            renderValue={(selected) => selected.join(', ')}
                                        >
                                            {classOptions.map((cls) => (
                                                <MenuItem key={cls} value={cls}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="secondary"
                                                                checked={formData.teachingClasses.includes(cls)}
                                                                value={cls}
                                                            />
                                                        }
                                                        label={cls}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="flex justify-center items-center w-full md:w-1/2 p-2">
                                    {/* Teach All Subjects */}
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                checked={formData.teachAllSubjects}
                                                onChange={handleTeachAllSubjectsChange}
                                            />
                                        }
                                        label="Teach All Subjects"
                                    />
                                </div>
                            </div>

                            {/* Subject Selection - only show if 'teachAllSubjects' is false and classes greater than 8 are selected */}
                            {!formData.teachAllSubjects && shouldShowSubjects() && (
                                <div>
                                    <div>
                                        {/* <h3 className="text-lg font-semibold">Select Subjects</h3> */}
                                        <FormControl fullWidth variant="outlined" color="secondary">
                                            <InputLabel>Subjects</InputLabel>
                                            <Select
                                                label="Subjects"
                                                name="selectedSubjects"
                                                multiple
                                                value={formData.selectedSubjects}
                                                onChange={handleSubjectChange}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                                {getSubjectOptions().map((subject) => (
                                                    <MenuItem key={subject} value={subject}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    color="secondary"
                                                                    checked={formData.selectedSubjects.includes(subject)}
                                                                    value={subject}
                                                                />
                                                            }
                                                            label={subject}
                                                        />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>


                                    <div className="pt-4">
                                        {/* <h4 className="text-lg font-semibold mt-4">Select Expertise</h4> */}
                                        <FormControl fullWidth variant="outlined" color="secondary">
                                            <InputLabel>Expertise</InputLabel>
                                            <Select
                                                label="Expertise"
                                                name="selectedExpertise"
                                                multiple
                                                value={formData.selectedExpertise}
                                                onChange={(event) => handleExpertiseChange(event.target.value, event.target.checked)} // Handle expertise change
                                                renderValue={(selected) => selected.join(", ")}
                                                MenuProps={MenuProps}

                                            >
                                                {getExpertiseOptions().map((expertise) => (
                                                    <MenuItem key={expertise} value={expertise}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    color="secondary"
                                                                    checked={formData.selectedExpertise.includes(expertise)}
                                                                    onChange={(event) => handleExpertiseChange(expertise, event.target.checked)} // Handle expertise change
                                                                />
                                                            }
                                                            label={expertise}
                                                        />
                                                    </MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>
                                    </div>

                                </div>
                            )}
                        </div>
                    )}

                    <div className="flex flex-wrap justify-between">
                        <div className="w-full md:w-1/2">
                            {/* Gender Select */}
                            <FormControl fullWidth variant="outlined" color="secondary" required>
                                <InputLabel>Gender</InputLabel>
                                <Select
                                    label="Gender"
                                    name="gender"
                                    value={formData.gender}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="male">Male</MenuItem>
                                    <MenuItem value="female">Female</MenuItem>
                                    <MenuItem value="other">Other</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        {/* Date of Birth */}

                        <div className="w-full md:w-1/2 pl-3">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Date of Birth"
                                    value={formData.dateOfBirth ? new Date(formData.dateOfBirth) : null} // Convert to Date object if string
                                    onChange={(newValue) => {
                                        if (newValue instanceof Date && !isNaN(newValue)) {
                                            const formattedDate = formatDateString(newValue); // Format the date directly from newValue
                                            setFormData({ ...formData, dateOfBirth: formattedDate });
                                        } else {
                                            setFormData({ ...formData, dateOfBirth: '' }); // Clear the date if invalid
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            color="secondary"
                                            variant="outlined"
                                            fullWidth={false}
                                        />
                                    )}
                                    required
                                />
                            </LocalizationProvider>
                        </div>

                    </div>
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className="w-full py-2"
                    >
                        Next
                    </Button>
                </form>
            ) : (
                // Second Form: Address
                <form
                    onSubmit={handleSubmit}
                    className="bg-white p-6 rounded shadow-md w-full max-w-lg space-y-4"
                >
                    <h2 className="text-2xl font-bold text-center mb-2">Address Information</h2>

                    {/* Address Fields */}
                    <TextField
                        label="Area"
                        color="secondary"
                        name="area"
                        variant="outlined"
                        fullWidth
                        value={formData.area}
                        onChange={handleChange}
                        required
                    />

                    <TextField
                        label="City"
                        color="secondary"
                        name="city"
                        variant="outlined"
                        fullWidth
                        value={formData.city}
                        onChange={handleChange}
                        required
                    />

                    <TextField
                        label="District"
                        color="secondary"
                        name="district"
                        variant="outlined"
                        fullWidth
                        value={formData.district}
                        onChange={handleChange}
                        required
                    />

                    <TextField
                        label="State"
                        color="secondary"
                        name="state"
                        variant="outlined"
                        fullWidth
                        value={formData.state}
                        onChange={handleChange}
                        required
                    />

                    <TextField
                        label="Pincode"
                        color="secondary"
                        name="pincode"
                        variant="outlined"
                        fullWidth
                        value={formData.pincode}
                        onChange={handleChange}
                        required
                    />

                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className="w-full py-2"
                    >
                        Submit Address
                    </Button>
                </form>
            )}
        </div>
    );
};

export default UserAddress;
