import { BrowserRouter } from "react-router-dom";
import Footer from "./layout/Footer";
import Navbar from "./layout/Navbar";

import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/lineawesome.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./assets/css/custom.css";
import MainRoutes from "Routes/MainRoutes";
import LoginPopup from "components/Modals/LoginPopup";
import { useEffect, useState,useRef } from "react";
import io  from "socket.io-client";
import { useGlobalContext } from "global/context";
import toast, { Toaster } from "react-hot-toast";
import { Stack } from "@mui/joy";
import PopUpMessage from "pages/static/Careers/components/MessageComponents/PopUpMessage";
import is from "date-fns/locale/is";
import MobileFooter from "pages/static/Home/components/MobileFooter";

function App() {
  const [ismobile, setIsmobile] = useState(window.innerWidth <= 768);
  const { socket, setSocket, userData, showMessage, setShowMessage, messageTo, onlineUsers, setOnlineUsers, getCategories } = useGlobalContext();
  const socketRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      setIsmobile(window.innerWidth <= 768)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }

  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  let endpoint = process.env.REACT_APP_API_ENDPOINT || "http://localhost:5000"

  useEffect(() => {

    const socket = io.connect(endpoint);

    console.log("Socket : ",socket)
    socket.on('connect', () => {
      setSocket(socket)
      console.log("Socket connected");
    });

    socket.on('disconnect', () => {
      setSocket(socket)
      console.log("Socket disconnected");
    });
    // console.log("Socket connected with ID:", socket.id);

    return () => {
      socket.disconnect();
      console.log("Socket disconnected on cleanup");
    };
  }, [userData?.id]);
  


  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (socket) {
      // Listen for USER_ADDED event
      socket.on("USER_ADDED", (updatedOnlineUsers) => {
        // console.log("Updated list of online users:", updatedOnlineUsers);
        setOnlineUsers(updatedOnlineUsers); // Update state with the new list of online users
      });

      // Clean up the event listener on unmount
      // return () => {
      //   socket.off("USER_ADDED");
      // };
    }
  }, [socket, setOnlineUsers]);

  // Track disconnections and reconnections
socket?.on("connect", () => {
  console.log("Socket ID:", socket?.id);
});
socket?.on("disconnect", () => {
  console.log("Socket disconnected");
});

useEffect(() => {
  if (socket) {
    // console.log("Setting up RECIVED_MSG listener on socket with ID:", socket?.id);
    socket?.on("RECIVED_MSG", (data) => {
      console.log("Received RECIVED_MSG:", data); // Debug message
      setShowMessage(true);
    });

    // Clean up the event listener when socket is cleaned up
    return () => {
      socket?.off("RECIVED_MSG");
    };
  }
}, [socket, setShowMessage]);

  
  // useEffect(() => {
  //   if (socket) {
  //     // console.log("Frontend socket connected:", socket?.connected, "with socket.id:", socket?.id);
  //   }
  // }, [socket]);

  useEffect(() => {
    if (socket && userData?.id) {
      socket.emit("newUser", userData.id);
    }
  }, [socket, userData?.id]);

  // useEffect(() => {
  //   socket?.on("USER_ADDED", (data) => {
  //     console.log("Online Users:", data);
  //     setOnlineUsers(data);
  //   });
  //   socket?.on("user-left", (data) => {
  //     console.log("User left:", data);
  //   });
  // }, [socket]);


  return (
    <BrowserRouter>
      <LoginPopup />
      <Navbar socket={socket} />
      {ismobile ? (
        <>
          <MainRoutes />
          <MobileFooter />
        </>
      ) : (
        <>
          <MainRoutes />
          <Footer />
        </>)}

      <div className="container">
        <PopUpMessage
          windowOpen={showMessage}
          setWindowOpen={setShowMessage}
          user={messageTo}
        />
      </div>
    </BrowserRouter>
  );
}

export default App;
