import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import { Box, Button, MenuItem, Select, TextField } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
// import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import Swal from "sweetalert2";
import * as yup from "yup";
import { Popup } from "../../../layout/Popup";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalContext } from "global/context";
import subjectData from "../../../data/subject.json"

const RegisterForm = () => {
  const { api } = useGlobalContext();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [registeredId, setRegisteredId] = useState();
  const endpoint = process.env.REACT_APP_API_ENDPOINT || "http://localhost:5000/";
  const [skills, setSkills] = useState([]);
  const [email, setEmail] = useState("");
  const [timeRemaining, setTimeRemaining] = useState();
  const navigate = useNavigate();
 const [role, setRole] = useState("")
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getSkiils = async () => {
    try {
      let res = await api.get("/app/tags");
      if (res?.status === 200) {
        setSkills(res?.data?.tags);
      } else {
        return [];
      }
    } catch (error) {
      console.log("Skills Error: ", error);
    }
  };

  useEffect(() => {
    getSkiils();
    loginWithToken(); // Call loginWithToken on component mount
  }, []);

  const loginWithToken = async () => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get('token');

    if (token) {
      const response = await fetch(`/address?token=${token}`);
      const data = await response.json();
      console.log("Data : ",data)

      if (data.jwt) {
        localStorage.setItem('authToken', data.jwt);
        window.location.href = data.redirectTo;
      }
    }
  };

  //Formik
  const validationSchema = yup.object({
    // profession: yup.string().required("Please select your role"),
    first_name: yup.string().required("First name is required"),
    middle_name: yup.string(),
    last_name: yup.string().required("Last name is required"),
    email: yup.string().required("Email address is required"),
    contact: yup.string().required("please enter Contect number").min(10).max(10).matches(/^[6-9]\d{9}$/, { message: "Please enter valid number.", excludeEmptyString: false }),
    password: yup.string().required("Password is required"),
    confirm_password: yup.string().oneOf([yup.ref("password"), null], "Confirm password must match"),
  });
  const Formik = useFormik({
    initialValues: {
      profession: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      email: "",
      education: "",
      contact: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, action) => {
      // console.log("Values : ",values)
      setRole(values.profession)
      Popup("loading", "Submitting...");
      try {
        const res = await api.post("app/auth/signup", values);

        if (res.status === 200) {
          Popup("warning", res?.data?.message);
          setRegisteredId(res?.data?.results?.insertId);
          setTimeRemaining(res?.data?.expirationTime);
          startTimeCounter(res?.data?.expirationTime);
        }
      } catch (error) {
        if (error.response.status === 409) {
          console.log(
            "data",
            error.response.data.message,
            error.response.data.desc
          );
          //   Popup(
          //     "warning",
          //     error.response.data.message,
          //     error.response.data.desc
          //   );

          Swal.fire({
            title: error.response.data.message,
            html: error.response.data.desc,
            showDenyButton: true,
            padding: "3em",
            showCancelButton: false,
            confirmButtonText: "Login",
            denyButtonText: `Reset Password`,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/address", { state: { profession: values.profession } });
            } else if (result.isDenied) {
              navigate("/auth/recover");
            }
          });
        } else {
          Popup("error", error.response.data.message);
        }
      }
    },
  });

  const OtpFormik = useFormik({
    initialValues: {
      code: "",
    },
    onSubmit: async (values, action) => {
      Popup("loading", "Submitting...");
      try {
        const res = await api.post(`/app/auth/verify-otp`, {
          ...values,
          userId: registeredId,
        });
        if (res.status === 200) {
          Popup("success", res.data.message);
          // console.log("Pro : ",role)
          navigate("/address", { state: {role:role,registeredId:registeredId}});
          window.location.reload();
        }
      } catch (error) {
        Popup("error", error.response.data.message);
      }
    },
  });
  const resendOTP = async () => {
    try {
      const res = await api.post(endpoint + "app/auth/send-otp", {
        email
      });
      if (res.status === 200) {
        Popup("success", res.data.message);
        // setExpirationTime(res.data.expirationTime);
        // setTimeRemaining(res.data.expirationTime);
        startTimeCounter(res.data.expirationTime);
      }
    } catch (error) {
      // console.log(error);
      Popup("error", error.response.data.message);
    }
  };

  const startTimeCounter = (expirationTime) => {
    const interval = setInterval(() => {
      // Calculate time remaining
      const now = new Date().getTime();
      const expiration = new Date(expirationTime).getTime();
      const timeDiff = expiration - now;
      const newTimeRemaining = Math.max(0, timeDiff);

      // Calculate remaining time in minutes and seconds
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

      // Update state with new time remaining
      setTimeRemaining({ minutes, seconds });

      // Stop interval if time has expired
      if (newTimeRemaining === 0) {
        clearInterval(interval);
      }
      // console.log("Time remaining: ", newTimeRemaining);
    }, 1000);

    return () => clearInterval(interval);
  }

  return (
    <>
      {!registeredId && (
        <form
          className="form-action-wrapper py-5 px-3 p-lg-5"
          onSubmit={Formik.handleSubmit}
        >
          <div className="form-group">
            <h3 className="fs-22 pb-3 fw-bold">Register to Eksathi</h3>
            <div className="divider">
              <span></span>
            </div>
          </div>
          <div>
            <div className="row row-cols-1">
              <div className="col d-flex align-items-center mb-3">
                {/* <>I am a {' '}</> */}
                <FormControl fullWidth>
                  <InputLabel id="i-am-a-label">I am a</InputLabel>
                  <Select
                    labelId="i-am-a-label"
                    id="profession"
                    name="profession"
                    value={Formik.values.profession}
                    label="I am a"
                    onChange={Formik.handleChange}
                    error={
                      Formik.touched.profession &&
                      Boolean(Formik.errors.profession)
                    }
                  // helpertext={
                  //   Formik.touched.profession && Formik.errors.profession
                  // }
                  >
                    {/* <MenuItem value="">
                    <em>Choose any</em>
                  </MenuItem> */}
                    <MenuItem value={"student"}>Student</MenuItem>
                    <MenuItem value={"teacher"}>Teacher</MenuItem>
                    <MenuItem value={"professional"}>Professional</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {/* <div className=" col mb-3 ">
                <Autocomplete
                  disablePortal
                  fullWidth
                  id="combo-box-demo"
                  options={subjects.map((subject) => subject.Category)}
                  // sx={{ width: 300 }}
                  onChange={(event, value) => {
                    const selectedSubject = subjects.find(
                      (subject) => subject.Category === value
                    );

                    // Extract skills based on selected subject
                    const skills = selectedSubject ? Object.entries(selectedSubject.Subjects).flatMap(([subjectName, topics]) => topics) : [];
                    console.log("Selected skills: ", skills);

                    console.log("Subjects: ", selectedSubject ? selectedSubject.Subjects : []);
                    console.log("Selected Subject: ", value);

                    Formik.setFieldValue("subject", value);
                    Formik.setFieldValue("skills", skills); // Set skills as subject keys (e.g., Physics, Chemistry, Biology)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Subject" />
                  )}
                />
              </div> */}
              {/* {Formik.values.profession === "professional" ||
                Formik.values.profession === "teacher" ? (
                <div className="col d-flex align-items-center mb-3">
                  <FormControl fullWidth>
                    <InputLabel id="expertise-in-label">Expertise in</InputLabel>
                    <Select
                      labelId="expertise-in-label"
                      id="skill"
                      name="skill"
                      value={Formik.values.skill}
                      label="Expertise in"
                      onChange={Formik.handleChange}
                    >
                      
                      {Formik.values.skills?.map((expertise, index) => (
                        <MenuItem key={index} value={expertise}>
                          {expertise}
                        </MenuItem>
                      ))}

                    </Select>
                  </FormControl>
                </div>
              ) : (
                <div className="col d-flex align-items-center mb-3">
                  
                  <FormControl fullWidth>
                    <InputLabel id="highest-education-label">
                      Highest Education
                    </InputLabel>
                    <Select
                      labelId="highest-education-label"
                      id="education"
                      name="education"
                      value={Formik.values.education}
                      label="Highest Education"
                      onChange={Formik.handleChange}
                    >
                      <MenuItem value={"Below 8th Standard"}>
                        Below 8th Standard
                      </MenuItem>
                      <MenuItem value={"8th Standard"}>8th Standard</MenuItem>
                      <MenuItem value={"9th Standard"}>9th Standard</MenuItem>
                      <MenuItem value={"High School"}>High School</MenuItem>
                      <MenuItem value={"Intermediate"}>Intermediate</MenuItem>
                      <MenuItem value={"Diploma"}>Diploma</MenuItem>
                      <MenuItem value={"Polytechnic"}>Polytechnic</MenuItem>
                      <MenuItem value={"Graduation"}>Graduation</MenuItem>
                      <MenuItem value={"Post Graduation"}>
                        Post Graduation
                      </MenuItem>
                      <MenuItem value={"Doctorate"}>Doctorate</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )} */}
            </div>
            {/* <label className="fs-14 text-black fw-medium lh-18">
              Full name
            </label> */}
            <Box
              component={"div"}
              className="row row-cols-1 row-cols-lg-3 g-2  mb-3"
            >
              <div className="col mb-2 mb-lg-0">
                <TextField
                  fullWidth
                  placeholder="First Name"
                  id="first_name"
                  name="first_name"
                  label="First Name"
                  value={Formik.values.first_name}
                  onChange={Formik.handleChange}
                  error={
                    Formik.touched.first_name &&
                    Boolean(Formik.errors.first_name)
                  }
                  helpertext={
                    Formik.touched.first_name && Formik.errors.first_name
                  }
                />
                
              </div>
              <div className="col mb-2 mb-lg-0">
                <TextField
                  fullWidth
                  placeholder="Middle Name"
                  id="middle_name"
                  name="middle_name"
                  label="Middle Name"
                  value={Formik.values.middle_name}
                  onChange={Formik.handleChange}
                  error={
                    Formik.touched.middle_name &&
                    Boolean(Formik.errors.middle_name)
                  }
                  helpertext={
                    Formik.touched.middle_name && Formik.errors.middle_name
                  }
                />
              </div>
              <div className="col mb-2 mb-lg-0">
                <TextField
                  placeholder="Last Name"
                  fullWidth
                  id="last_name"
                  name="last_name"
                  label="Last Name"
                  value={Formik.values.last_name}
                  onChange={Formik.handleChange}
                  error={
                    Formik.touched.last_name && Boolean(Formik.errors.last_name)
                  }
                  helpertext={
                    Formik.touched.last_name && Formik.errors.last_name
                  }
                />
              </div>
            </Box>
          </div>
          {/* end form-group */}
          <div className="form-group">
            {/* <label className="fs-14 text-black fw-medium lh-18">Email</label> */}
            <TextField
              placeholder="Your Email Address"
              fullWidth
              label={"Email Address"}
              type={"email"}
              id="email"
              name="email"
              value={Formik.values.email}
              onChange={Formik.handleChange}
              error={Formik.touched.email && Boolean(Formik.errors.email)}
              helpertext={Formik.touched.email && Formik.errors.email}
            />
          </div>
          <div className="form-group">
            {/* <label className="fs-14 text-black fw-medium lh-18">Contact Number</label> */}
            <TextField
              placeholder="Your Contact"
              fullWidth
              label={"Contact Number"}
              type={"tel"}
              id="contact"
              name="contact"
              value={Formik.values.contact}
              onChange={Formik.handleChange}
              error={
                Formik.touched.contact &&
                Boolean(Formik.errors.contact)
              }
              helpertext={
                Formik.touched.contact && Formik.errors.contact
              }
            />
          </div>
          {/* end form-group */}
          <div className="form-group">
            {/* <label className="fs-14 text-black fw-medium lh-18">
             Enter Your Password
              </label> */}
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                type={showPassword ? "text" : "password"}
                placeholder="Your Password"
                id="password"
                name="password"
                label="password"
                value={Formik.values.password}
                onChange={Formik.handleChange}
                error={
                  Formik.touched.password && Boolean(Formik.errors.password)
                }
                helpertext={
                  Formik.touched.password && Formik.errors.password
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              // label="Password"
              />
              {Formik.touched.password && (
                <FormHelperText
                  sx={{ color: "red" }}>
                  {Formik.errors.password}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          {/* end form-group */}
          <div className="form-group">
            {/* <label className="fs-14 text-black fw-medium lh-18">
              Confirm Password
            </label> */}
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Confirm password
              </InputLabel>
              <OutlinedInput
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                id="confirm_password"
                name="confirm_password"
                label="Confim Password"
                value={Formik.values.confirm_password}
                onChange={Formik.handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {Formik.touched.confirm_password && (
                <FormHelperText sx={{ color: "red" }}>
                  {Formik.errors.confirm_password}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <Button
            fullWidth
            type="submit"
            size="large"
            sx={{ p: 2 }}
            color="primary"
            variant="contained"
          >
            Register
          </Button>
          {/* end form-group */}
          <p className=" mt-3 pb-3">
            By clicking “Register”, you agree to our{" "}
            <Link to="/terms-of-service" className="text-color hover-underline">
              terms of conditions
            </Link>
            ,{" "}
            <Link to="/privacy-policy" className="text-color hover-underline">
              privacy policy
            </Link>
          </p>
          <p className="text-center fw-bold">
            Are you a Institute?{" "}
            <span>
              <Link to="/auth/institute/signup">Click here</Link>
            </span>
          </p>
        </form>
      )}
      {registeredId && (
        <>
          <form
            className="form-action-wrapper py-5"
            onSubmit={OtpFormik.handleSubmit}
          >
            <div className="form-group">
              <h3 className="fs-22 pb-3 fw-bold">Check your email address for the otp</h3>
              <div className="divider">
                <span></span>
              </div>
            </div>
            <div className="form-group">
              <label className="fs-14 text-black fw-medium lh-18">
                One Time Password
              </label>
              <TextField
                fullWidth
                label={"Enter your OTP"}
                id="code"
                name="code"
                value={OtpFormik.values.code}
                onChange={OtpFormik.handleChange}
                error={OtpFormik.touched.code && Boolean(OtpFormik.errors.code)}
                helperText={OtpFormik.touched.code && OtpFormik.errors.code}
              />
            </div>
            <Button
              fullWidth
              type="submit"
              size="large"
              sx={{ p: 2 }}
              color="primary"
              variant="contained"
            >
              Verify
            </Button>
          </form>
          <div className="text-center">

            {
              timeRemaining?.seconds >= 0 ?
                <p><span className="text-danger">{`${timeRemaining?.minutes}:${timeRemaining?.seconds}`}</span> left</p>
                : <button disabled={timeRemaining?.seconds <= 0 ? false : true} type="button" className="btn btn-link" onClick={resendOTP}>Resend OTP</button>
            }
            {/* OTP not recieved?  */}

          </div>
        </>
      )}
    </>
  );
};

export default RegisterForm;
