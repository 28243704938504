import { Search } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { Popup } from "layout/Popup";
import { useLocation } from "react-router";
import GetStarted from "./GetStarted";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useGlobalContext } from '../../../../global/context'
import SmallJobCard from "./SmallJobCard";
import { display } from "@mui/system";
import GetStartedMobile from "./GetStartedMobile";
import { Select, MenuItem, ListItemIcon, ListItemText, FormControl, InputLabel } from "@mui/material";
import { FaQuestion } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import GyaniRating from "./GyaniRating";
import useDidMountEffect from 'global/useDidMountEffect'

const Hero = () => {
  const navigate = useNavigate();
  const { api, userData } = useGlobalContext();
  const [jobs, setJobs] = useState([]);
  const [searchType, setSearchType] = useState('question');
  const [placeHolder, setPlaceHolder] = useState(' Search by Question ....')
  const [keyword, setKeyword] = useState('');
  const [colorText, setColorText] = useState('Teachers, Students & Institutes');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const location = useLocation();
  const [showGyaniModal, setShowGyaniModal] = React.useState(false);
  const [suggestedExperts, setSuggestedExperts] = useState([]);
  const [userAddress, setUserAddress] = useState('');
  const [matchedJobs, setMatchedJobs] = useState([]);
  const [unmatchedJobs, setUnmatchedJobs] = useState([]);

  const colors = ["#FFF1F1", "#FFFEC5", "#D0FB91", "#CAFEDC", "#E6F9F3", "#DEEAFC", "#E3E5FF", "#F6D4FE", "#FFEDF7", "#F0F0F0", "#E6FFDF"];

  useEffect(() => {
    getJobs();
    getUserLocation();
    getGyanis();
  }, []);


// console.log("UserAddress : " ,userAddress)
  useEffect(() => {
    // Filter jobs based on user address and job location
    if (userAddress) {
      const matched = [];
      const unmatched = [];

      jobs.forEach((jd) => {
        const userAddressLower = userAddress.toLowerCase();
        const jobLocationWords = jd.job_location.toLowerCase().split(/\s+/);

        const isMatch = jobLocationWords.some((word) => userAddressLower.includes(word));

        if (isMatch) matched.push(jd);
        else unmatched.push(jd);
      });

      setMatchedJobs(matched);
      setUnmatchedJobs(unmatched);
    }
  }, [userAddress, jobs]);

  async function getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          // const latitude = 20.6349993
          // const longitude = 79.3674217

          try {
            const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
            const response = await api.get(url);
            if (response.data) {
              setUserAddress(response.data.display_name);
            } else {
              throw new Error("Unable to retrieve address");
            }
          } catch (error) {
            console.error("Error getting address:", error);
          }
        },
        (error) => {
          console.error(`Error getting location: ${error.message}`);
        }
      );
    }
  }



  useEffect(() => {
    // Check if the modal should be opened based on the navigation state
    if (location.state && location.state.openGyaniModal && suggestedExperts.length > 0) {
      setShowGyaniModal(true);
    }
  }, [location.state]);

  const toggleGyaniModal = () => {
    setShowGyaniModal(prev => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)

    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }

  }, [])

  const getGyanis = async () => {

    try {
      const res = await api.get(`/app/user/rateGyani?userId=${userData?.id}`);
      if (res?.status === 200) {
        setSuggestedExperts(res?.data?.results);

        if (res.data.results && res.data.results.length > 0) {
          setShowGyaniModal(true);
        } else {
          setShowGyaniModal(false);
        }
      }
    } catch (e) {
      console.log(e);
      Popup("error", e?.response?.data?.message);
    }
  };

  useDidMountEffect(() => {
    // console.log({ locality, subLocality });
    getGyanis();
  }, []);

  const getJobs = async () => {
    try {
      const res = await api.get(`/app/jobs/active`);
      if (res?.status === 200) {
        console.log("Job was successfully Fetchech: ", res?.data);
        setJobs(res?.data?.results);
      }
    } catch (err) {
      console.log("Error getting jobs", err);
    }
  }

  // console.log("jobs : ", jobs)

  const handleChange = (event) => {
    // setSearchTypeLocal(event.target.value);
    setSearchType(event.target.value); // This updates the parent state
  };

  const handleSearch = () => {
    if (keyword && searchType === 'question') {
      console.log("Send Keyword from Home Page : ", keyword)
      navigate(`/questions`, { state: { keyword: keyword } });
    } else if (keyword && searchType === 'user') {
      console.log("Send Keyword from Home Page : ", keyword)
      navigate(`/users`, { state: { keyword: keyword } });
    }
  }
  const handleSearchTypeChange = (event) => {
    // const selectedType = event.target.value;
    // searchType(selectedType)
    if (searchType === "question") {
      setPlaceHolder(" Search By Question .....")
    }
    else {
      setPlaceHolder(" Search By User .....")
    }
  }

  useEffect(() => {
    handleSearchTypeChange()
  }, [searchType])
  useEffect(() => {
    setPlaceHolder(" Search By Question .....")
  }, [])

  const generateRandomLightColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };

  return (
    <section className="hero-area overflow-hidden bg-light py-2"
    // style={{
    //   backgroundImage: "url(https://img.freepik.com/free-vector/science-doodle-pattern_23-2147492328.jpg?1&w=826&t=st=1686820031~exp=1686820631~hmac=fc9b7676fa577ce7c94ea2f54201e23e80aaf833aaed72dfa0f6c55c423e7e05)",
    //   opacity: '0.4'
    // }}
    >
      <span className="icon-shape icon-shape-1 is-scale" />
      <span className="icon-shape icon-shape-2 is-bounce" />
      <span className="icon-shape icon-shape-3 is-swing" />
      <span className="icon-shape icon-shape-4 is-spin" />
      <span className="icon-shape icon-shape-5 is-spin" />
      <span className="icon-shape icon-shape-6 is-bounce" />
      <span className="icon-shape icon-shape-7 is-tilt" />
      <div className="container">
        <div className="search-bar-small">
          <div className="input-group bg-white mb-1"
            style={{
              border: '1px solid #ddd',
              borderRadius: '16px',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
            }}>
            <input type="text" className=""
              placeholder={placeHolder}
              onChange={(e) => setKeyword(e.target.value)}
              style={{
                border: 'none',
                outline: 'none',
                padding: '8px',
                borderRadius: '16px',
                flexGrow: 1,
              }}
            />
            <Select
              className=""
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={searchType}
              label="Search Type"
              onChange={handleChange}
              sx={{
                minWidth: '50px',
                maxWidth: '50px',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& .MuiSelect-select': {
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '40px', // Ensure there's enough space for the dropdown icon
                },
                '& .MuiSelect-icon': {
                  position: 'absolute', // Position the dropdown icon absolutely
                  right: '2px', // Adjust this value to control the space from the right
                }
              }}
            >
              <MenuItem value="question" sx={{ display: 'flex', alignItems: 'center' }}>
                <ListItemIcon sx={{ marginRight: '' }}>
                  <FaQuestion />
                </ListItemIcon>
                Questions
              </MenuItem>
              <MenuItem value="user" sx={{ display: 'flex', alignItems: 'center' }}>
                <ListItemIcon sx={{ marginRight: '' }}>
                  <FaUserAlt />
                </ListItemIcon>
                Users
              </MenuItem>
            </Select>
            <button
              className=""
              onClick={handleSearch}
              style={{
                backgroundColor: '#28a745',
                color: 'white',
                padding: '8px 16px',
                borderRadius: '16px',
                border: 'none',
                cursor: 'pointer'
              }}
            >
              <Search />
            </button>
          </div>
        </div>

        {jobs.length > 0 && !isMobile && (
          <div style={{ backgroundColor: "#FFECEC", borderRadius: "5px", height: "110px" }}>
            <h5 style={{ textAlign: "left", marginLeft: "5px" }}>Current Vacancies</h5>
            <marquee behavior="scroll" direction="right" scrollamount="5">
              {[
                ...matchedJobs,                              // First, include all matched jobs
                ...unmatchedJobs                             // Then include unmatched jobs as needed
              ]
                .slice(0, 5)                                 // Limit to the first 5 jobs
                .map((jd) => (
                  <div
                    key={jd.id}
                    style={{
                      display: 'inline-block',
                      backgroundColor: generateRandomLightColor(),
                      border: '1px solid #ddd',
                      borderRadius: '5px',
                      marginLeft: '15px',
                      padding: '9px',
                      width: '130px',
                      height: '80px',
                    }}
                  >
                    <SmallJobCard key={jd.id} jd={jd} matched={matchedJobs.includes(jd)} />
                  </div>
                ))}
            </marquee>
          </div>
        )}

        <div className="row justify-content-center ">
          <div className="col-lg-9">
            <div className="hero-content text-center py-lg-1">
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h2 className="section-title-f text-black">
                  Join growing community of Educators for free!!!
                </h2>
              </div>
              <h2 className="section-title-s text-black linear-wipe">
                {colorText}!
              </h2>
              <h5 className="section-title-t text-secondary mx-auto text-dark">
                Learn by asking questions, find earning opportunities & internships, and Network with topic level Gyani's
              </h5>
              <div className="d-flex justify-content-center">
                <div className="search-bar-large" >
                  <div className="input-group bg-white p-2 rounded-pill shadow">
                    <input type="text" className="form-control border-0 shadow-0 py-4"
                      placeholder={placeHolder}
                      onChange={(e) => setKeyword(e.target.value)}
                      style={{ border: 'none', boxShadow: 'none', outline: 'none', width: "600px" }}
                    />
                    <select name="" id="" className="form-control mb-0 h-100 mr-3 border-top-0 border-bottom-0"
                      style={{ maxWidth: "120px" }}
                      onChange={(e) => setSearchType(e.target.value)}
                    // onChange={handleSearchTypeChange}
                    >
                      <option value="question">Question</option>
                      <option value="user">User</option>
                    </select>
                    <button
                      className="text-capitalize btn btn-success rounded-pill px-3"
                      onClick={handleSearch}
                    >
                      <Search style={{ fontSize: 18 }} /> Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* end hero-content */}
          </div>
          {/* end col-lg-6 */}
        </div>
      </div>
      {
        isMobile ? (<GetStartedMobile />) :
          (<GetStarted />)}
      <GyaniRating isOpen={showGyaniModal} toggleModal={toggleGyaniModal} suggestedExperts={suggestedExperts} setSuggestedExperts={setSuggestedExperts} />
    </section>
  );
};

export default Hero;
