import { Add, CheckCircle, CheckCircleTwoTone, Circle, DeselectRounded, HourglassTopTwoTone, Image, MailTwoTone, PendingActions, PendingActionsTwoTone, PersonAddAlt, PersonAddAltSharp, PunchClockTwoTone, SmsFailedRounded, Star, StarRounded } from '@mui/icons-material'
import { Avatar, Box, Divider, IconButton, Tooltip } from '@mui/joy'
import { Button, Grid, List, ListItem, ListItemAvatar, ListItemText, Rating, Typography } from '@mui/material'
import { useGlobalContext } from 'global/context'
import { Popup } from 'layout/Popup'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Geocode from "react-geocode"
import { toast } from 'react-hot-toast'
import useDidMountEffect from 'global/useDidMountEffect'
// import useCurrentLocation from 'global/useCurrentLocation'
import styled from 'styled-components';

// A styled button that will appear on small screens
const FloatingButton = styled.button`
position: fixed;
bottom: 20px;
right: 10px;
background: linear-gradient(145deg, #ffffff, #e6e6e6); /* Light gradient for depth */
color: black;
border: none; /* Remove the default border */
border-radius: 8px; /* Softer corners */
padding: 7px; /* Ample padding for a larger button */
font-size: 12px; /* Increased font size for better visibility */
font-weight: 600;
z-index: 1000;
display: none; /* Initially hidden */
cursor: pointer; /* Change cursor to pointer */

/* 3D effect with shadows on both sides */
box-shadow: 
  6px 6px 15px rgba(0, 0, 0, 0.2), /* Strong bottom right shadow */
  -4px -4px 10px rgba(255, 255, 255, 0.9), /* Strong top left highlight */
  0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle depth shadow */

@media (max-width: 992px) {
  display: block; /* Show button on screens 992px or less */
}

&:hover {
  box-shadow: 
    8px 8px 20px rgba(0, 0, 0, 0.3), /* Enhanced bottom right shadow */
    -6px -6px 15px rgba(255, 255, 255, 0.9), /* Enhanced top left highlight */
    0 3px 8px rgba(0, 0, 0, 0.2); /* Slightly stronger depth shadow */
  transform: translateY(-4px); /* Lift the button slightly on hover */
  transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition */
}

&:active {
  box-shadow: 
    inset 4px 4px 10px rgba(0, 0, 0, 0.5), /* Inset shadow when active */
    inset -4px -4px 10px rgba(255, 255, 255, 0.5); /* Inset highlight */
  transform: translateY(0); /* Reset the lift effect */
}
`;


// The overlay background (glass effect)
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Glass-like blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease, visibility 0.3s ease;
`;

// The modal container for SuggestedExperts
const ModalContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  max-height: 80vh;
  overflow-y: auto; /* Scrollable content inside the modal */
`;

// A close button inside the modal
const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
`;

const labels = {
    0.5: 'Just Started',
    1: 'Just Started+',
    1.5: 'Still Learning',
    2: 'Still Learning+',
    2.5: 'Gaining Expertise',
    3: 'Gaining Expertise+',
    3.5: 'Expert Now',
    4: 'Expert Now+',
    4.5: 'Param Gyani',
    5: 'Param Gyani+',
};

function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

const ExpertRow = ({ data, index, setSuggestedExperts }) => {
    const { userData, api } =
        useGlobalContext();
    const [value, setValue] = React.useState(null);
    const [hover, setHover] = React.useState(-1);

    // Check if the user is the same as the expert
    if (userData?.id === data?.id) {
        return null; // Do not render this row if the user is the expert
    }
    
    const handleRating = async (newRating, expertId) => {
        if (!newRating || !expertId || !userData?.id) {
            alert("Rating submission failed. Please make sure all required values are available and try again.");
            return;
        }
        console.log(`New rating: ${newRating} for expert ID: ${expertId}`);
        try {
            const res = await api.patch(`/app/rating/gyani?userId=${userData?.id}`, {
                rating: newRating,
                ratedUserId: expertId // Passing the expert ID to the API
            });
            if (res?.status === 200) {
                console.log("Rating Response: ", res);
                setSuggestedExperts(prevExperts => prevExperts.filter(expert => expert.id !== expertId));
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    }
    console.log("Data : ",data)

    return (
        <Box
            sx={{
                padding: '10px',
                borderRadius: '8px',
                backgroundColor: '#f5f5f5',
                width: '100%',
                marginBottom:'2px',
            }}
        >
            <Grid container spacing={2} alignItems="center">
                {/* Row 1: Avatar and Name */}
                <Grid item xs={3} sm={2} md={1}>
                    <Avatar src={data?.avatar_url} sx={{ height: 40, width: 40 }} />
                </Grid>
                <Grid item xs={9} sm={10} md={5}>
                    <Link to={`/${data?.username}`} className="text-dark fw-bold">
                        {data?.first_name} {data?.last_name}
                    </Link>
                </Grid>
        
                {/* Row 2: Rating and Value */}
                <Grid item xs={7} sm={8} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Rating
                        name="hover-feedback"
                        value={value}
                        precision={0.5}
                        onChange={(event, newValue) => {
                                    setValue(newValue);
                                    handleRating(newValue, data?.id);
                                }}
                        onChangeActive={(event, newHover) => setHover(newHover)}
                        emptyIcon={<Star style={{ opacity: 0.55 }} fontSize="inherit" />}
                    />
                </Grid>
                <Grid item xs={5} sm={4} md={2} sx={{ textAlign: 'center' }}>
                    {value !== null && (
                        <Typography variant="body2">
                            {labels[hover !== -1 ? hover : value]}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Box>
        
    );
}

const GyaniRating = ({ isOpen, toggleModal, suggestedExperts=[] , setSuggestedExperts}) => {
    const { api, userData } = useGlobalContext();
    const [heading, setHeading] = useState("Rate Your Gyani's");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Effect to handle window resizing and update windowWidth
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <FloatingButton onClick={toggleModal}>Nearest Gyani's</FloatingButton>
            <Overlay isOpen={isOpen}  onClick={toggleModal}>
                <div className="relative bg-white w-4/5 max-w-xl max-h-[70vh] rounded-lg p-4 flex flex-col overflow-hidden"
                onClick={(e) => e.stopPropagation()}
                >
                    <div className="flex justify-between items-center sticky top-0 bg-white pb-2 z-10">
                        <h5 className="text-lg font-semibold">{heading}</h5>
                        <button
                            onClick={toggleModal}
                            className="text-2xl cursor-pointer focus:outline-none"
                        >
                            &times;
                        </button>
                    </div>
                    <div className="w-full h-px bg-gray-300 my-2" />
                    <div className="overflow-y-auto flex-grow pt-2 mt-2">
                        {suggestedExperts.map((data, index) => (
                            <ExpertRow
                             data={data}
                              key={index}
                              setSuggestedExperts={setSuggestedExperts} // Pass the function here
                              />
                        ))}
                    </div>
                </div>
            </Overlay>
        </>

    );
};

export default GyaniRating;