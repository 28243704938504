import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  ListItem,
  ListItemButton,
  ListItemDecorator,
  ListItemContent,
} from "@mui/joy";
import { List } from "@mui/joy";
import { People, Info } from "@mui/icons-material";
import { Col } from "react-bootstrap";
import { useGlobalContext } from "global/context";
import toast from "react-hot-toast";
const fontFamily = "'Quicksand', sans-serif";

const SidebarMenuItem = ({
  index,
  icon,
  text,
  to,
  selectedIndex,
  setIndex,
  color,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {// Log notifications here
    setIndex(index);
  };
  return (
    <Link to={to}>
      <ListItem className="mb-1">
        <ListItemButton
          selected={selectedIndex === index}
          variant={selectedIndex === index ? "solid" : "plain"}
          color={selectedIndex === index ? color : undefined}
          onClick={handleClick}
          className="rounded mb-1"
        >
          <ListItemDecorator className="p-2 rounded">{icon}</ListItemDecorator>
          <ListItemContent sx={{ fontFamily: fontFamily, fontWeight: "bold" }}>
            {text}
          </ListItemContent>
        </ListItemButton>
      </ListItem>
    </Link>
  );
};

function InstituteSidebar() {
  const { userData, api, notification, setNotification } = useGlobalContext();
  const [index, setIndex] = useState(0);
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [isMessageActive, setIsMessageActive] = useState(false);


  const getNotifications = async () => {
    try {
      const res = await api.get(`/app/notification/${userData?.id}?type=5`);
      if (res?.status === 200) {
        setNotification(res?.data?.notifications);
      }
    } catch (error) {
      // toast.error(error?.response?.data?.message || "Error getting notifications");
    }
  }

  // console.log("Notification in SideBar : : ", notification)

  const handleAccordionClick = () => {
    setIsActive((prevIsActive) => !prevIsActive);
  };
  const handleMessageAccordionClick = () => {
    setIsMessageActive((prev) => !prev);
  };

  const panelStyle = {
    padding: "0 8px",
    maxHeight: isActive ? "100%" : "0",
    opacity: isActive ? "1" : "0",
    overflow: "hidden",
    transition: "max-height 0.4s, opacity 0.4s",
  };
  const MessageStyle = {
    padding: "0 8px",
    maxHeight: isMessageActive ? "100%" : "0",
    opacity: isMessageActive ? "1" : "0",
    overflow: "hidden",
  };

  useEffect(() => {
    getNotifications()
    const path = location.pathname;
    if (path === "/institute/dashboard") setIndex(1);
    else if (path === "/institute/profile/departments") setIndex(2);
    else if (path.startsWith("/institute/profile")) setIndex(3);
    else if (path.startsWith("/institute/notification")) setIndex(4);
    else if (path.startsWith("/institute/api")) setIndex(5);
    else if (path.startsWith("/institute/jobs")) setIndex(6);
    else if (path.startsWith("/institutes/jobspost")) setIndex(7);
    else if (path.startsWith("/institute/messages")) setIndex(8);
    else if (path.startsWith("/institute/admissions")) setIndex(9);
    else if (path.startsWith("/institute/postadmissions")) setIndex(10);
    else if (path.startsWith("/institute/event")) setIndex(11);
  }, [location.pathname]);

  return (
    <div
      className="d-flex flex-column justify-content-end h-[50vh] md:h-[70vh]"
      style={{ background: "#f8f9fa" }}
    >
      <Col>
        <div className="rounded-3 mt-3">
          <div className="p-1 mt-0">
            {/* Dashboard */}
            <SidebarMenuItem
              index={1}
              icon={<i className="bi bi-database-dash"></i>}
              text="Dashboard"
              to="/institute/dashboard"
              selectedIndex={index}
              setIndex={setIndex}
              color="warning"
            />
            {/* Profile */}
            <SidebarMenuItem
              index={3}
              icon={<i className="bi bi-person"></i>}
              text="Profile"
              to="/institute/profile"
              selectedIndex={index}
              setIndex={setIndex}
              color="info"
            />
            {/* Events */}
            <SidebarMenuItem
              index={11}
              icon={<i className="bi bi-calendar-event"></i>}
              text="Events"
              to="/institute/event"
              selectedIndex={index}
              setIndex={setIndex}
              color="success"
            />
            {/* Departments */}
            <SidebarMenuItem
              index={2}
              icon={<i className="bi bi-people"></i>}
              text="Departments"
              to="/institute/profile/departments"
              selectedIndex={index}
              setIndex={setIndex}
              color="primary"
            />
            {/* Notification */}
            <SidebarMenuItem
              index={4}
              icon={<i className="bi bi-bell"></i>}
              text="Notification"
              to="/institute/notification"
              selectedIndex={index}
              setIndex={setIndex}
              color="success"
            />
            {/* Api */}
            <SidebarMenuItem
              index={5}
              icon={<i className="bi bi-code-slash"></i>}
              text="Api"
              to="/institute/api"
              selectedIndex={index}
              setIndex={setIndex}
              color="info"
            />
            {/* Manage Jobs */}
            <div className="rounded">
              <div>
                <ListItemButton
                  className="rounded"
                  onClick={handleAccordionClick}
                >
                  <ListItemDecorator className="p-2 rounded">
                    <i className="bi bi-caret-down"></i>
                  </ListItemDecorator>
                  <ListItemContent
                    sx={{ fontFamily: fontFamily, fontWeight: "bold" }}
                  >
                    Manage Jobs
                  </ListItemContent>
                </ListItemButton>
                <div style={panelStyle} className="">
                  <List>
                    {/* Jobs */}
                    <SidebarMenuItem
                      index={6}
                      icon={<People fontSize="lg" />}
                      text="Jobs"
                      to="/institute/jobs"
                      selectedIndex={index}
                      setIndex={setIndex}
                      color="danger"
                    />
                    {/* Post Job */}
                    <SidebarMenuItem
                      index={7}
                      icon={<Info fontSize="lg" />}
                      text="Post Job"
                      to="/institutes/jobspost"
                      selectedIndex={index}
                      setIndex={setIndex}
                      color="warning"
                    />
                  </List>
                </div>
              </div>
            </div>
            {/* Messages */}
            <SidebarMenuItem
              index={8}
              icon={<i className="bi bi-chat-left-dots mt-1"></i>}
              text="Message"
              to="/institute/messages"
              selectedIndex={index}
              setIndex={setIndex}
              color="neutral"// Pass notifications
            />
            {/* Admission */}
            <div>
              <div onClick={handleMessageAccordionClick}>
                <ListItemButton className="rounded">
                  <ListItemDecorator className="p-2 rounded">
                    <i className="bi bi-caret-down"></i>
                  </ListItemDecorator>
                  <ListItemContent
                    sx={{ fontFamily: fontFamily, fontWeight: "bold" }}
                  >
                    Manage Admissions
                  </ListItemContent>
                </ListItemButton>
              </div>
              <div style={MessageStyle}>
                <SidebarMenuItem
                  index={9}
                  icon={<i className="bi bi-person-check"></i>}
                  text="Admission"
                  to="/institute/admissions"
                  selectedIndex={index}
                  setIndex={setIndex}
                  color="success"
                />
                <SidebarMenuItem
                  index={10}
                  icon={<i className="bi bi-plus-circle"></i>}
                  text="PostAdmission"
                  to="/institute/postadmissions"
                  selectedIndex={index}
                  setIndex={setIndex}
                  color="info"
                />
              </div>
            </div>
          </div>
        </div>
      </Col>
    </div>
  );
}

export default InstituteSidebar;
